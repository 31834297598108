import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import commonConstants from '../../constants/common.constants';

export const PROPERTY_KEY = {
  PAGE: 'page',
  RANGE_DATE: 'rangeDate',
  KEYWORD: 'q',
  CATEGORY: 'category',
} as const;

const initialState = {
  page: commonConstants.LEARN_DEFAULT_QUERY_PARAMS.page,
  rangeDate: [
    // dateUtil.getDate(-5 /* 5 days previously */),
    // dateUtil.getDate(1 /* Day of tomorrow */),
    null,
    null,
  ] as Date[] | null[],
  q: '' as string,
  category: '',
};

type PayloadProperty = {
  type: ValueOf<typeof PROPERTY_KEY>;
  value: ValueOf<typeof initialState>;
};

type PayloadProperties = PayloadProperty[];

const LearnsSlice = createSlice({
  name: 'learns',
  initialState,
  reducers: {
    setProperty: (state, action: PayloadAction<PayloadProperty>) => {
      // @ts-ignore
      state[action.payload.type] = action.payload.value;
    },
    setProperties: (state, action: PayloadAction<PayloadProperties>) => {
      if (action.payload.length > 0) {
        action.payload.forEach((item: PayloadProperty) => {
          if (state.hasOwnProperty(item.type)) {
            // @ts-ignore
            state[item.type] = item.value;
          }
        });
      }
    },
  },
});

export const { setProperty, setProperties } = LearnsSlice.actions;

export default LearnsSlice.reducer;
