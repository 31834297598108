import ToastMessage, { ToastType } from '@/components/common/ToastMessage/ToastMessage';
import httpStatusCode from '@/constants/httpStatusCode.constants';
import { logout } from '@/redux/slices/authSlice';
import { persistor } from '@/redux/store';
import axios from '@/utils/axios.util';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

const useAxios = (name: string = '') => {
  const dispatch = useDispatch();
  useEffect(() => {
    const responseInterceptor = axios.interceptors.response.use(
      (response) => response,
      (error) => {
        const errorMessage = error?.response?.data ?? {
          message: 'Something went wrong.',
        };
        if (error?.response?.status === httpStatusCode.UNAUTHENTICATED) {
          toast(
            <ToastMessage
              text={errorMessage.message}
              type={ToastType.ERROR.type}
            />
          );
          persistor.purge();
          dispatch(logout());
        }
        return Promise.reject(error);
      }
    );
    return () => {
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, [dispatch, name]);

  return axios;
};

export default useAxios;
