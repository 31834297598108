export const RESET_PASSWORD_TOKEN = 'RESET_PASSWORD_TOKEN';
export const DAILY_EXERCISES_ORDER = 'DAILY_EXERCISES_ORDER';
export const JOURNEY_VIDEOS_ORDER = 'JOURNEY_VIDEOS_ORDER';
export const DAILY_EXERCISES = 'DAILY_EXERCISES';
export const DAILY_EXERCISE = 'DAILY_EXERCISE';
export const FOCUS_AUDIO = 'FOCUS_AUDIO';
export const MEDITATION = 'MEDITATION';
export const INTRO_EXERCISES_ORDER = 'INTRO_EXERCISES_ORDER';
export const INTRO_EXERCISES = 'INTRO_EXERCISES';
export const INTRO_EXERCISE = 'INTRO_EXERCISE';
export const ARTICLES = 'ARTICLES';
export const ARTICLE = 'ARTICLE';
export const EVENTS = 'EVENTS';
export const EVENT = 'EVENT';
export const PODCASTS = 'PODCASTS';
export const PODCAST = 'PODCAST';
export const PROMO_ANALYTICS = 'PROMO_ANALYTICS';
export const JOURNEYS = 'JOURNEYS';
export const JOURNEY_CATEGORIES = 'JOURNEY_CATEGORIES';
export const JOURNEY_CATEGORY = 'JOURNEY_CATEGORY';

const getQueryKey = {
  resetPasswordTokenKey: (token: string): any[] => [
    RESET_PASSWORD_TOKEN,
    token,
  ],
  dailyExercisesOrderQueryKey: (category: string): any[] => [
    DAILY_EXERCISES_ORDER,
    { category },
  ],
  journeyVideosOrderQueryKey: (type: string, category: string): any[] => [
    JOURNEY_VIDEOS_ORDER,
    { type, category },
  ],
  dailyExercisesQueryKey: (
    page: number,
    keyword: string,
    rangeDate: any[],
    category: string
  ): any[] => [
    DAILY_EXERCISES,
    { page },
    { keyword },
    { rangeDate },
    { category },
  ],
  dailyExerciseQueryKey: (exerciseId: string): any[] => [
    DAILY_EXERCISE,
    exerciseId,
  ],
  meditationQueryKey: (meditationId: string): any[] => [
    MEDITATION,
    meditationId,
  ],
  focusAudioQueryKey: (focusAudioId: string): any[] => [
    FOCUS_AUDIO,
    focusAudioId,
  ],
  introExercisesOrderQueryKey: (): any[] => [INTRO_EXERCISES_ORDER],
  introExercisesQueryKey: (page: number, keyword: string): any[] => [
    INTRO_EXERCISES,
    { page },
    { keyword },
  ],
  introExerciseQueryKey: (exerciseId: string): any[] => [
    INTRO_EXERCISE,
    exerciseId,
  ],
  articlesQueryKey: (
    page: number,
    keyword: string,
    category: string
  ): any[] => [ARTICLES, { page }, { keyword }, { category }],
  articleQueryKey: (learnId: string): any[] => [ARTICLE, learnId],
  eventsQueryKey: (page: number, keyword: string, rangeDate: any[]): any[] => [
    EVENTS,
    { page },
    { keyword },
    { rangeDate },
  ],
  eventQueryKey: (eventId: string): any[] => [EVENT, eventId],
  podcastsQueryKey: (
    page: number,
    keyword: string,
    rangeDate: any[]
  ): any[] => [PODCASTS, { page }, { keyword }, { rangeDate }],
  podcastQueryKey: (podcastId: string): any[] => [PODCAST, podcastId],
  promoAnalyticsQueryKey: (page: number, rangeDate: any[]): any[] => [
    PROMO_ANALYTICS,
    { page },
    { rangeDate },
  ],
  journeysQueryKey: (queryObject: any) => [JOURNEYS, queryObject],
  journeyCategoriesQueryKey: (type: string) => [JOURNEY_CATEGORIES, type],
  journeyCategoryQueryKey: (categoryId: string) => [
    JOURNEY_CATEGORY,
    categoryId,
  ],
};

export default getQueryKey;
