import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authReducer from './slices/authSlice';
import dailyExercisesReducer from './slices/dailyExercisesSlice';
import eventsReducer from './slices/eventsSlice';
import introExercisesReducer from './slices/introExercisesSlice';
import learnsReducer from './slices/learnsSlice';
import podcastsReducer from './slices/podcastsSlice';
import promoAnalyticsReducer from './slices/promoAnalyticsSlice';
import serverOptionsReducer from './slices/serverOptionsSlice';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: ['auth'],
};

const rootReducer = combineReducers({
  auth: authReducer,
  learns: learnsReducer,
  dailyExercises: dailyExercisesReducer,
  introExercises: introExercisesReducer,
  events: eventsReducer,
  podcasts: podcastsReducer,
  promoAnalytics: promoAnalyticsReducer,
  serverOptions: serverOptionsReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;

export default store;
