import { format } from 'date-fns';
import moment from 'moment';

const parseUtil = {
  parseDateRangeIntoString: (dateFrom: Date, dateTo: Date) => {
    const dateFromString = format(dateFrom, 'dd/MM/yyyy');
    const dateToString = format(dateTo, 'dd/MM/yyyy');
    return { dateFromString, dateToString };
  },
  parseStringIntoDateRange: (dateRangeString: string) => {
    const [dateFromString, dateToString] = dateRangeString.split('-');
    const dateFrom = moment(dateFromString.trim(), 'DD/MM/YYYY').toDate();
    const dateTo = moment(dateToString.trim(), 'DD/MM/YYYY').toDate();
    return { dateFrom, dateTo };
  },
  parseTimeRangeIntoString: (timeFrom: Date, timeTo: Date) => {
    const timeFromString = format(timeFrom, 'kk:mm');
    const timeToString = format(timeTo, 'kk:mm');
    return { timeFromString, timeToString };
  },
  parseStringIntoTimeRange: (timeRangeString: string) => {
    const [timeFromString, timeToString] = timeRangeString.split('-');
    const timeFrom = moment(timeFromString.trim(), 'kk:mm').toDate();
    const timeTo = moment(timeToString.trim(), 'kk:mm').toDate();
    return { timeFrom, timeTo };
  },
};

export default parseUtil;
