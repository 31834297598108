import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import commonConstants from '../../constants/common.constants';

export const PROPERTY_KEY = {
  PAGE: 'page',
  KEYWORD: 'q',
} as const;

const initialState = {
  page: commonConstants.INTRO_EXERCISE_DEFAULT_QUERY_PARAMS.page,
  q: '' as string,
} as const;

type PayloadProperty = {
  type: ValueOf<typeof PROPERTY_KEY>;
  value: ValueOf<typeof initialState>;
};

type PayloadProperties = PayloadProperty[];

const IntroExercisesSlice = createSlice({
  name: 'introExercises',
  initialState,
  reducers: {
    setProperty: (state, action: PayloadAction<PayloadProperty>) => {
      // @ts-ignore
      state[action.payload.type] = action.payload.value;
    },
    setProperties: (state, action: PayloadAction<PayloadProperties>) => {
      if (action.payload.length > 0) {
        action.payload.forEach((item: PayloadProperty) => {
          if (state.hasOwnProperty(item.type)) {
            // @ts-ignore
            state[item.type] = item.value;
          }
        });
      }
    },
  },
});

export const { setProperty, setProperties } = IntroExercisesSlice.actions;

export default IntroExercisesSlice.reducer;
