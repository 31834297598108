import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import commonConstants from '@/constants/common.constants';
import { getDailyExercisesFilterProperties } from '@/redux/selectors/dailyExercises.selector';
import { getEventsFilterProperties } from '@/redux/selectors/events.selector';
import { getLearnsFilterProperties } from '@/redux/selectors/learns.selector';
import { getPodcastsFilterProperties } from '@/redux/selectors/podcasts.selector';
import { getPromoAnalyticsFilterProperties } from '@/redux/selectors/promoAnalytics.selector';
import {
  PROPERTY_KEY,
  setProperties as setDailyExercisesProperties,
} from '@/redux/slices/dailyExercisesSlice';
import { setProperties as setEventsProperties } from '@/redux/slices/eventsSlice';
import { setProperties as setLearnsProperties } from '@/redux/slices/learnsSlice';
import { setProperties as setPodcastsProperties } from '@/redux/slices/podcastsSlice';
import { setProperties as setPromoAnalyticsProperties } from '@/redux/slices/promoAnalyticsSlice';
import dateUtil from '@/utils/date.util';
import { Button, Stack, styled } from '@mui/material';
import { useState } from 'react';
import { DateRange } from 'react-date-range';
import { useDispatch, useSelector } from 'react-redux';

export const PAGES_TYPE = {
  DAILY_EXERCISES: 'DAILY_EXERCISES',
  LEARNS: 'LEARNS',
  EVENTS: 'EVENTS',
  PODCASTS: 'PODCASTS',
  PROMO_ANALYTICS: 'PROMO_ANALYTICS',
};

const PAGES = {
  DAILY_EXERCISES: {
    getProperties: getDailyExercisesFilterProperties,
    setProperties: setDailyExercisesProperties,
  },
  LEARNS: {
    getProperties: getLearnsFilterProperties,
    setProperties: setLearnsProperties,
  },
  EVENTS: {
    getProperties: getEventsFilterProperties,
    setProperties: setEventsProperties,
  },
  PODCASTS: {
    getProperties: getPodcastsFilterProperties,
    setProperties: setPodcastsProperties,
  },
  PROMO_ANALYTICS: {
    getProperties: getPromoAnalyticsFilterProperties,
    setProperties: setPromoAnalyticsProperties,
  },
};

export const ClearButton = styled(Button)({
  height: '40px',
  textTransform: 'unset',
  border: '1px solid #141965',
  borderRadius: '100px',
  color: '#fff',
  backgroundColor: '#141965',
  '&:hover': {
    backgroundColor: '#141965',
    opacity: 0.9,
  },
});

type DayRangePickerModalProps = {
  onCloseModal: () => void;
  data: any;
};

const DayRangePickerModal = ({
  onCloseModal,
  data,
}: DayRangePickerModalProps) => {
  const { page } = data;
  // @ts-ignore
  const { getProperties, setProperties } = PAGES[page];
  // @ts-ignore
  const { rangeDate } = useSelector(getProperties);
  const dispatch = useDispatch();
  const [ranges, setRanges] = useState([
    {
      startDate: rangeDate[0],
      endDate: rangeDate[1],
      key: 'selection',
    },
  ]);

  const onChangeDate = (item: any) => {
    const formattedStartDate = dateUtil.commonFormattedDate(
      item.selection.startDate
    );
    const formattedEndDate = dateUtil.commonFormattedDate(
      item.selection.endDate
    );
    setRanges([item.selection]);
    if (formattedStartDate !== formattedEndDate) {
      dispatch(
        setProperties([
          {
            type: PROPERTY_KEY.RANGE_DATE,
            value: [item.selection.startDate, item.selection.endDate],
          },
          {
            type: PROPERTY_KEY.PAGE,
            value: commonConstants.DAILY_EXERCISE_DEFAULT_QUERY_PARAMS.page,
          },
        ])
      );
      onCloseModal();
    }
  };
  return (
    <Stack gap={2}>
      <ClearButton
        onClick={() => {
          dispatch(
            setProperties([
              {
                type: PROPERTY_KEY.RANGE_DATE,
                value: [null, null],
              },
              {
                type: PROPERTY_KEY.PAGE,
                value: commonConstants.DAILY_EXERCISE_DEFAULT_QUERY_PARAMS.page,
              },
            ])
          );
          onCloseModal();
        }}
        fullWidth
        variant="contained"
      >
        Clear Date
      </ClearButton>
      <DateRange onChange={onChangeDate} ranges={ranges} />
    </Stack>
  );
};

export default DayRangePickerModal;
