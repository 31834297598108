import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import { ToastContentProps } from 'react-toastify';
import { AlertBox, AlertText } from './ToastMessageStyled';

export const ToastType = {
  SUCCESS: {
    type: 'SUCCESS',
    styled: {
      backgroundColor: '#DFE3E',
      border: '2px solid #1EAD54',
    },
  },
  ERROR: {
    type: 'ERROR',
    styled: {
      backgroundColor: 'rgba(240, 0, 35, 0.03);',
      border: '2px solid #FF0000',
    },
  },
  WARNING: {
    type: 'WARNING',
    styled: {
      backgroundColor: 'rgba(240, 0, 35, 0.03);',
      border: '2px solid #ffd900',
    },
  },
} as const;

type ToastMessageProps = {
  type: keyof typeof ToastType;
  text: string;
};

const ToastMessage = ({
  closeToast,
  toastProps,
  type = ToastType.SUCCESS.type,
  text,
}: Partial<ToastContentProps & ToastMessageProps>) => {
  // @ts-ignore
  const styled = ToastType[type]?.styled ?? ToastType.SUCCESS.styled;
  return (
    <AlertBox sx={styled}>
      <AlertText>{text}</AlertText>
      <IconButton onClick={closeToast}>
        <CloseIcon />
      </IconButton>
    </AlertBox>
  );
};

export default ToastMessage;
