import { themes } from '@/theme';
import { Button, styled, Container } from '@mui/material';

export const Wrapper = styled(Container)({
  height: '100%',
  marginTop: '2rem',
  marginBottom: '3rem',
});

export const ActionButton = styled(Button)({
  textTransform: 'unset',
  padding: '0.5rem 2rem',
  color: themes.light.colorDeepSapphire,
  borderColor: themes.light.colorDeepSapphire,
  fontSize: '1rem',
  fontWeight: 500,
});

export const ActionButtonContained = styled(Button)({
  textTransform: 'unset',
  padding: '0.5rem 2rem',
  color: themes.light.colorWhite,
  borderColor: themes.light.colorDeepSapphire,
  backgroundColor: themes.light.colorDeepSapphire,
  fontSize: '1rem',
  fontWeight: 500,
  '&:hover': {
    color: themes.light.colorDeepSapphire,
    backgroundColor: themes.light.colorWhite,
    border: `1px solid ${themes.light.colorDeepSapphire}`,
  },
});

export const StyledButton = styled(Button)({
  width: '150px',
  height: '40px',
  textTransform: 'unset',
  // border: `1px solid ${themes.light.colorDeepSapphire}`,
  borderRadius: '100px',
});

export const ActionCircleButtonContained = styled(StyledButton)({
  color: '#fff',
  backgroundColor: themes.light.colorDeepSapphire,
  '&:hover': {
    backgroundColor: themes.light.colorDeepSapphire,
    opacity: 0.9,
  },
});

export const ActionCircleButtonContainedSelected = styled(
  ActionCircleButtonContained
)({
  backgroundColor: themes.light.colorDesert,
  borderColor: 'inherit',
  '&:hover': {
    backgroundColor: themes.light.colorDesert,
  },
});
