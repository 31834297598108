import ToastMessage, { ToastType } from '@/components/common/ToastMessage/ToastMessage';
import { useReducer } from 'react';
import { toast } from 'react-toastify';

// import {MAX_FILE_SIZE_BYTES} from 'src/constants';

const initialFileState = {
  fileError: null,
  fileName: null,
  fileSize: null,
  fileType: null,
  fileContents: null,
};

// function bytesToMb(bytes: number) {
//   const mb = bytes / 1000000;

//   return mb;
// }

const EXTERNAL_FILES = ['srt', 'vtt', 'wav', 'mp3'];

export function useFileChange(types: string[]) {
  const [
    { fileError, fileContents, fileName, fileSize, fileType },
    fileDispatch,
  ] = useReducer(fileChangeReducer, initialFileState);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }

    let [type] = fileObj.type.split('/');
    if (EXTERNAL_FILES.some((item) => types.includes(item))) {
      type = fileObj?.name?.split('.').slice(-1)[0];
    }

    if (!type || !types.some((item) => item === type)) {
      fileDispatch({
        type: 'FILE_CHANGE_FAILURE',
        fileError: `You can only upload ${types.join(', ')} files.`,
      });
      toast(
        <ToastMessage
          text={`You can only upload ${types.join(', ')} files.`}
          type={ToastType.ERROR.type}
        />
      );
      return;
    }

    // if (fileObj.size > MAX_FILE_SIZE_BYTES) {
    //   fileDispatch({
    //     type: 'FILE_CHANGE_FAILURE',
    //     fileError: `File is too large, file size is ${bytesToMb(
    //       fileObj.size,
    //     ).toFixed(2)} MB, maximum allowed size - 1 MB.`,
    //   });
    //   return;
    // }

    // eslint-disable-next-line no-param-reassign
    event.target.value = '';

    fileDispatch({
      type: 'FILE_CHANGE_SUCCESS',
      fileName: fileObj.name,
      fileSize: fileObj.size,
      fileType: fileObj.type !== '' ? fileObj.type : type,
      fileContents: fileObj,
    });
  };

  return {
    fileError,
    fileContents,
    fileName,
    fileType,
    fileSize,
    handleFileChange,
    fileDispatch,
  };
}

type FileState = {
  fileError: string | null;
  fileName: string | null;
  fileSize: number | null;
  fileType: string | null;
  fileContents: File | null;
};

type FileChangeAction =
  | {
      type: 'FILE_CHANGE_SUCCESS';
      fileName: string;
      fileSize: number;
      fileType: string;
      fileContents: File;
    }
  | { type: 'FILE_CHANGE_FAILURE'; fileError: string }
  | { type: 'RESET_FILE_STATE' };

export const TYPE_DISPATCH = {
  FILE_CHANGE_SUCCESS: 'FILE_CHANGE_SUCCESS',
  FILE_CHANGE_FAILURE: 'FILE_CHANGE_FAILURE',
  RESET_FILE_STATE: 'RESET_FILE_STATE',
};

export function fileChangeReducer(
  _state: FileState,
  action: FileChangeAction
): FileState {
  switch (action.type) {
    case 'FILE_CHANGE_SUCCESS': {
      return {
        fileError: null,
        fileName: action.fileName,
        fileSize: action.fileSize,
        fileType: action.fileType,
        fileContents: action.fileContents,
      };
    }
    case 'FILE_CHANGE_FAILURE': {
      return {
        ...initialFileState,
        fileError: action.fileError,
      };
    }
    case 'RESET_FILE_STATE': {
      return initialFileState;
    }
    default: {
      throw new Error(`Unsupported action type: ${JSON.stringify(action)}`);
    }
  }
}
