import parseUtil from '@/utils/parse.util';
import { Button, Stack, TextField, Typography } from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { useEffect } from 'react';
import { useImmer } from 'use-immer';

const initialStateRanges = {
  timeFrom: null,
  timeTo: null,
};

const initialStateErrors = {
  timeFrom: '',
  timeTo: '',
  common: '',
};

type TimeRangePickerFormModalProps = {
  onCloseModal: () => void;
  data: any;
};

const TimeRangePickerFormModal = ({
  onCloseModal,
  data,
}: TimeRangePickerFormModalProps) => {
  const { setValue, value } = data;
  const [ranges, setRanges] = useImmer(initialStateRanges);
  const [errors, setErrors] = useImmer(initialStateErrors);
  useEffect(() => {
    if (!!value) {
      const { timeFrom, timeTo } = parseUtil.parseStringIntoTimeRange(value);
      setRanges((draft) => {
        // @ts-ignore
        draft.timeFrom = timeFrom;
        // @ts-ignore
        draft.timeTo = timeTo;
      });
    }
  }, [setRanges, value]);

  useEffect(() => {
    ['timeFrom', 'timeTo'].forEach((item: string) => {
      // @ts-ignore
      if (ranges[item]) {
        setErrors((draft) => {
          // @ts-ignore
          draft[item] = '';
        });
      }
    });
    if (!!ranges.timeFrom && !!ranges.timeTo) {
      const {
        timeFromString,
        timeToString,
      } = parseUtil.parseTimeRangeIntoString(ranges.timeFrom, ranges.timeTo);
      if (timeFromString !== timeToString) {
        setErrors((draft) => {
          draft.common = '';
        });
      }
    }
  }, [ranges, setErrors]);

  const handleOnConfirm = () => {
    let isAllValid = true;
    if (!ranges.timeFrom) {
      setErrors((draft) => {
        draft.timeFrom = 'Time From is required';
      });
    }
    if (!ranges.timeTo) {
      setErrors((draft) => {
        draft.timeTo = 'Time To is required';
      });
    }
    if (!isAllValid) {
      return;
    }
    const { timeFromString, timeToString } = parseUtil.parseTimeRangeIntoString(
      // @ts-ignore
      ranges.timeFrom,
      // @ts-ignore
      ranges.timeTo
    );
    if (timeFromString === timeToString) {
      setErrors((draft) => {
        draft.common = 'Time From and Time To should be different';
      });
      return;
    }
    setValue(`${timeFromString} - ${timeToString}`);
    onCloseModal();
  };
  return (
    <Stack gap={2}>
      <Stack sx={{ flexDirection: 'row', justifyContent: 'center' }} gap={1}>
        <TimePicker
          label="Time From"
          value={ranges.timeFrom}
          onChange={(newValue) => {
            if (moment(newValue, 'kk:mm').isValid()) {
              setRanges((draft) => {
                draft.timeFrom = newValue;
              });
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              error={Boolean(errors.timeFrom)}
              helperText={errors.timeFrom}
            />
          )}
        />
        <TimePicker
          disableMaskedInput={true}
          label="Time To"
          value={ranges.timeTo}
          onChange={(newValue) => {
            if (moment(newValue, 'kk:mm').isValid()) {
              setRanges((draft) => {
                draft.timeTo = newValue;
              });
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              error={Boolean(errors.timeTo)}
              helperText={errors.timeTo}
            />
          )}
        />
      </Stack>
      {errors.common && (
        <Typography sx={{ color: 'red', textAlign: 'center' }}>
          {errors.common}
        </Typography>
      )}
      <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <Button onClick={() => onCloseModal()}>Cancel</Button>
        <Button onClick={handleOnConfirm}>Confirm</Button>
      </Stack>
    </Stack>
  );
};

export default TimeRangePickerFormModal;
