import { getIsAuthenticated } from '@/redux/selectors/auth.selector';
import { Box } from '@mui/material';
import { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

type ProtectedRouteProps = {
  redirectPath?: string;
  children: ReactNode;
};

function ProtectedRoute({
  redirectPath = '/auth',
  children,
}: ProtectedRouteProps) {
  const isAuthenticated = useSelector(getIsAuthenticated);
  const location = useLocation();
  if (!isAuthenticated) {
    return <Navigate to={redirectPath} replace state={{ from: location }} />;
  }

  const content = children ?? <Outlet />;
  return <Box mb={8}>{content}</Box>;
}

export default ProtectedRoute;
