import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store';

export const getDailyExercisesFilterProperties = (state: RootState) =>
  state.dailyExercises;

export const getListOfObjectCategory = (state: RootState) =>
  state.serverOptions.exerciseCategories;

export const getListOfCategory = createSelector(
  [getListOfObjectCategory],
  (exerciseCategories) => exerciseCategories.map((item) => item.value)
);
