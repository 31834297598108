import {
  DateRangePickerFormModal,
  DayRangePickerModal,
  TimeRangePickerFormModal,
  DayRangePickerQueryParamsModal,
} from '@/components/modal/common';
import { ViewPodcastPlaysModal } from '@/components/modal/podcasts';
import { Backdrop, Box, CircularProgress, styled } from '@mui/material';
import Modal from '@mui/material/Modal';
import { useMemo, useState } from 'react';

export const MODAL_TYPES = {
  DAY_RANGE_PICKER: DayRangePickerModal,
  DAY_RANGE_PICKER_QUERY_PARAMS: DayRangePickerQueryParamsModal,
  TIME_RANGE_PICKER_FORM: TimeRangePickerFormModal,
  DATE_RANGE_PICKER_FORM: DateRangePickerFormModal,
  VIEW_PODCAST_PLAYS_FORM: ViewPodcastPlaysModal,
};

type OpenModalState = {
  modalType: any;
  data?: any;
};

const StyledModal = styled(Modal)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 400,
  maxWidth: 1000,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

type UseModalProps = {
  isLoading?: boolean;
  customStyle?: any;
};

const useModal = ({ isLoading, customStyle = {} }: UseModalProps) => {
  const [openModal, setOpenModal] = useState<OpenModalState | null>(null);
  const onHandleCloseModal = () => {
    setOpenModal(null);
  };
  const renderModal = useMemo(() => {
    const { modalType: ModalComponent, data } = openModal ?? {};
    if (ModalComponent) {
      return (
        <StyledModal
          open={!!openModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          onClose={onHandleCloseModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Box
            sx={style}
            style={{ ...customStyle, ...(data?.customStyle ?? {}) }}
          >
            {isLoading && (
              <Backdrop
                sx={{
                  color: '#fff',
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={true}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            )}
            <ModalComponent data={data} onCloseModal={onHandleCloseModal} />
          </Box>
        </StyledModal>
      );
    }
    return null;
  }, [customStyle, isLoading, openModal]);

  return { renderModal, setOpenModal };
};

export default useModal;
