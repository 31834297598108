import useModal, { MODAL_TYPES } from '@/hooks/modal/useModal';
import EventNoteIcon from '@mui/icons-material/EventNote';
import { IconButton, InputAdornment, styled, TextField } from '@mui/material';
import { useField } from 'formik';

const StyledField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: '1px solid #0C277B',
      borderRadius: '8px',
    },
  },
});

const TimeRange = (props: any) => {
  const { label, name, ...rest } = props;
  const [field, meta, helpers] = useField(name);
  const { renderModal, setOpenModal } = useModal({});

  const handleSetValue = (value: string) => {
    helpers.setValue(value, true /* shouldValidate */);
  };

  const onClickOpenModal = () => {
    setOpenModal({
      modalType: MODAL_TYPES.TIME_RANGE_PICKER_FORM,
      data: { setValue: handleSetValue, value: field.value },
    });
  };
  return (
    <>
      <StyledField
        value={field.value}
        error={Boolean(meta.error)}
        helperText={meta.touched && meta.error}
        {...rest}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={onClickOpenModal}>
                <EventNoteIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        variant="outlined"
      />
      {renderModal}
    </>
  );
};

export default TimeRange;
