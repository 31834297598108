import axios from '@/utils/axios.util';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export type Category = {
  label: string;
  value: string;
};

type AuthState = {
  learnCategories: [];
  exerciseCategories: Category[];
  eventStates: [];
};

const initialState: AuthState = {
  learnCategories: [],
  exerciseCategories: [],
  eventStates: [],
};

export const fetchDefaultOptions = createAsyncThunk(
  'serverOptions/fetchDefaultOptions',
  async () => {
    const response = await axios.get('/cms-learn/default-options');
    return response.data;
  }
);

const serverOptionsSlice = createSlice({
  name: 'serverOptions',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchDefaultOptions.fulfilled, (state, action) => {
      state.learnCategories = action.payload.learnCategories ?? [];
      state.exerciseCategories = action.payload.exerciseCategories ?? [];
      state.eventStates = action.payload.eventStates ?? [];
    });
  },
});

export default serverOptionsSlice.reducer;
