import { Autocomplete, styled, TextField } from '@mui/material';
import { useField } from 'formik';

const StyledField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: '1px solid #0C277B',
      borderRadius: '8px',
    },
  },
});

const AutocompleteSearch = (props: any) => {
  const { name, label, options, ...rest } = props;
  const [, meta, helper] = useField(name);

  const onHandleChange = async (e: any, value: any) => {
    if (!value) {
      helper.setValue('');
    } else {
      helper.setValue(value);
    }
  };
  return (
    <>
      <Autocomplete
        disablePortal
        onChange={onHandleChange}
        fullWidth
        value={meta.value}
        id='combo-box-demo'
        options={options ?? []}
        renderInput={(params: any) => (
          <StyledField
            {...params}
            label={label}
            fullWidth
            error={Boolean(meta.error)}
            helperText={meta.error}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password', // disable autocomplete and autofill
            }}
            {...rest}
          />
        )}
      />
    </>
  );
};

export default AutocompleteSearch;
