import { createTheme } from '@mui/material';

const lightTheme = {
  colorBlack: '#000000',
  colorWhite: '#FFFFFF',
  colorGray: '#646464',
  colorElectricRed: '#E10600',
  colorDeepSapphire: '#141965',
  colorDune: '#313131',
  colorDesert: '#D2AB66',
  colorComet: '#535F78',
  colorDeepLilac: '#8B56B0',
} as const;

// export type Theme = typeof lightTheme

const themes = {
  light: lightTheme,
  //add theme if customer like
} as const;

let themeMaterial = createTheme({
  typography: {
    fontFamily: 'SF Pro Display, sans-serif',
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'unset',
        },
      },
    },
  },
});

themeMaterial = createTheme(themeMaterial, {
  palette: {
    desert: themeMaterial.palette.augmentColor({
      color: {
        main: lightTheme.colorDesert,
      },
      name: 'desert',
    }),
    deepSapphire: themeMaterial.palette.augmentColor({
      color: {
        main: lightTheme.colorDeepSapphire,
      },
      name: 'deepSapphire',
    }),
  },
});

declare module '@mui/material/styles' {
  interface Palette {
    desert: Palette['primary'];
    deepSapphire: Palette['primary'];
  }

  interface PaletteOptions {
    desert?: PaletteOptions['primary'];
    deepSapphire?: PaletteOptions['primary'];
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    desert: true;
    deepSapphire: true;
  }
}

export { themeMaterial, themes };
