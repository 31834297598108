import UploadSection from '@/components/common/uploadSection/UploadSection';
import FormikControl, { CONTROL_TYPE } from '@/components/formik/FormikControl';
import commonConstants from '@/constants/common.constants';
import useDialog from '@/hooks/dialog/useDialog';
import commonUtil from '@/utils/common.util';
import { Form, FormikProvider, useFormik } from 'formik';
import { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { Stack } from '@mui/material';
import { StyledButton, Wrapper } from '@/components/common/Styled/CommonStyled';
import { Text32Weight600DeepSapphire } from '@/components/common/Styled/TypographyStyed';
import UploadAudio from '@/components/common/uploadSection/UploadAudio';
import {
  useCreateNewFocusAudio,
  useDeleteFocusAudio,
  useEditFocusAudio,
  useGetFocusAudio,
} from '@/hooks/focusAudios/useFocusAudio';

const validationSchema = yup.object({
  image: yup.string().required('Image is required.'),
  audio: yup.string().required('Audio is required.'),
  title: yup.string().required('Title is required.'),
  guideName: yup.string().required('Guide Name is required.'),
  description: yup.string().required('Description is required.'),
});

type CreateValues = yup.InferType<typeof validationSchema>;

const CreateEditFocusAudio = () => {
  const navigate = useNavigate();
  let { focusAudioId } = useParams();

  const isEditPage = useMemo(() => !!focusAudioId, [focusAudioId]);
  const { data } = useGetFocusAudio(focusAudioId as string);
  const { mutate: mutateCreateMeditationVideo, isLoading: isLoadingCreate } =
    useCreateNewFocusAudio();
  const { mutate: mutateEditMeditationVideo, isLoading: isLoadingEdit } =
    useEditFocusAudio(focusAudioId as string);
  const { mutate: mutateDeleteMeditationVideo, isLoading: isLoadingDelete } =
    useDeleteFocusAudio(focusAudioId as string);
  const mutate = isEditPage
    ? mutateEditMeditationVideo
    : mutateCreateMeditationVideo;
  const isLoading = isLoadingCreate || isLoadingEdit || isLoadingDelete;
  const { renderDialog, handleOpen } = useDialog(() => {
    mutateDeleteMeditationVideo();
  }, 'Are you sure you want to delete this Audio?' /* Dialog title */);
  const initialValues: CreateValues = useMemo(
    () => ({
      image: data?.image ?? '',
      audio: data?.audio ?? '',
      title: data?.title ?? '',
      guideName: data?.guideName ?? '',
      description: data?.description ?? '',
    }),
    [data]
  );

  const onSubmit = (values: CreateValues) => {
    const payload = {
      ...values,
      status: commonConstants.STATUS.PUBLISHED,
    };
    mutate(payload);
  };

  const handleDraft = async (
    values: CreateValues,
    validateForm: () => Promise<any>
  ) => {
    const errors = await validateForm();
    if (commonUtil.checkEmptyObject(errors)) {
      const payload = {
        ...values,
        status: commonConstants.STATUS.DRAFT,
      };
      mutate(payload);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });
  const { values, validateForm, errors, setFieldValue, handleSubmit } = formik;

  const handleAddAudio = useCallback(
    ({ title, url }: { title: string; url: string }) => {
      setFieldValue('audio', url);
    },
    [setFieldValue]
  );

  return (
    <FormikProvider value={formik}>
      <Wrapper maxWidth="xl">
        <Stack gap={2}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            {renderDialog}
            <Text32Weight600DeepSapphire>
              {isEditPage ? 'Edit' : 'Create'} Focus Audio
            </Text32Weight600DeepSapphire>
            <Stack direction="row" gap={2}>
              {isEditPage ? (
                <StyledButton
                  color="error"
                  variant="text"
                  onClick={() => {
                    handleOpen();
                  }}
                  disabled={isLoading}
                >
                  Delete
                </StyledButton>
              ) : (
                <StyledButton
                  color="error"
                  variant="text"
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </StyledButton>
              )}
              <StyledButton
                color="deepSapphire"
                variant="outlined"
                onClick={() => handleDraft(values, validateForm)}
                disabled={isLoading}
              >
                Save Changes
              </StyledButton>
              {/* @ts-ignore */}
              <StyledButton
                color="deepSapphire"
                variant="contained"
                onClick={(e) => handleSubmit()}
                disabled={isLoading}
              >
                Publish
              </StyledButton>
            </Stack>
          </Stack>
          <UploadSection
            errors={errors}
            name="image"
            setFieldValue={setFieldValue}
            value={values['image']}
            typeAllow={['image']}
            isVideo={false}
          />
          <UploadAudio
            error={errors.audio}
            handleAddAudio={handleAddAudio}
            addText={values.audio ? 'Change Audio' : 'Upload Audio'}
          />
          <Form>
            <Stack gap={2}>
              <FormikControl
                control={CONTROL_TYPE.INPUT}
                name="title"
                label="Title"
                placeholder="Exercise title"
                maxLength={150}
              />
              <FormikControl
                control={CONTROL_TYPE.INPUT}
                name="guideName"
                label="Guide Name"
              />
              <FormikControl
                control={CONTROL_TYPE.INPUT}
                name="description"
                label="Description"
                placeholder="Description"
              />
            </Stack>
          </Form>
        </Stack>
      </Wrapper>
    </FormikProvider>
  );
};

export default CreateEditFocusAudio;
