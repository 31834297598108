import parseUtil from '@/utils/parse.util';
import { Button, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { DateRange } from 'react-date-range';
import { useImmer } from 'use-immer';

const initialStateRanges = [
  {
    startDate: null,
    endDate: null,
    key: 'selection',
  },
];

type TimeRangePickerFormModalProps = {
  onCloseModal: () => void;
  data: any;
};

const DateRangePickerFormModal = ({
  onCloseModal,
  data,
}: TimeRangePickerFormModalProps) => {
  const { setValue, value } = data;
  const [ranges, setRanges] = useImmer(initialStateRanges);
  const [error, setError] = useState('');

  useEffect(() => {
    if (!!value) {
      const { dateFrom, dateTo } = parseUtil.parseStringIntoDateRange(value);
      setRanges((draft) => {
        // @ts-ignore
        draft[0].startDate = dateFrom;
        // @ts-ignore
        draft[0].endDate = dateTo;
      });
    }
  }, [setRanges, value]);

  useEffect(() => {
    if (!!ranges[0].startDate && !!ranges[0].endDate) {
      setError('');
    }
  }, [ranges]);
  const onChangeDate = (item: any) => {
    setRanges([item.selection]);
  };
  const handleOnConfirm = () => {
    if (!ranges[0].startDate || !ranges[0].endDate) {
      setError('Please select Date range');
      return;
    }
    const { dateFromString, dateToString } = parseUtil.parseDateRangeIntoString(
      ranges[0].startDate,
      ranges[0].endDate
    );
    setValue(`${dateFromString} - ${dateToString}`);
    onCloseModal();
  };
  return (
    <Stack gap={2}>
      {/* @ts-ignore */}
      <DateRange onChange={onChangeDate} ranges={ranges} />
      {error && (
        <Typography sx={{ color: 'red', textAlign: 'center' }}>
          {error}
        </Typography>
      )}
      <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <Button onClick={() => onCloseModal()}>Cancel</Button>
        <Button onClick={handleOnConfirm}>Confirm</Button>
      </Stack>
    </Stack>
  );
};

export default DateRangePickerFormModal;
