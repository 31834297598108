import httpStatusCode from '@/constants/httpStatusCode.constants';
import { useFileChange } from '@/hooks/common/useFileChange';
import { getSignUrl, useUploadToS3 } from '@/hooks/common/useUploadToS3';
import AddIcon from '@mui/icons-material/Add';
import { Button, Stack } from '@mui/material';
import { useEffect, useId } from 'react';
import { UploadVideoSection } from './UploadSectionStyled';

type UploadTranscriptProps = {
  error: string | undefined;
  handleAddAudio: ({ title, url }: { title: string; url: string }) => void;
  typeAllow?: string[];
  addText?: string;
};

const UploadAudio = ({
  error,
  handleAddAudio,
  typeAllow = ['wav', 'mp3'], // ['wav', 'mp3']
  addText = 'Upload Audio',
}: UploadTranscriptProps) => {
  const { fileContents, fileType, handleFileChange, fileName } =
    useFileChange(typeAllow);
  const id = useId();

  const { mutateAsync, isLoading } = useUploadToS3();

  useEffect(() => {
    const handleUpload = async () => {
      try {
        if (fileType && fileContents && fileName) {
          const response = await getSignUrl(fileType);
          if (response.status === httpStatusCode.OK) {
            const { fileUrl, putUrl } = response?.data;
            const res = await mutateAsync({ fileContents, fileType, putUrl });
            if (res.status === httpStatusCode.OK) {
              let newFilename = fileName.replace(/\.[^/.]+$/, '');
              handleAddAudio({ title: newFilename, url: fileUrl });
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    handleUpload();
  }, [fileType, fileContents, mutateAsync, fileName, handleAddAudio]);

  return (
    // @ts-ignore
    <UploadVideoSection isError={Boolean(error)} height="100px">
      <Stack
        direction="row"
        justifyContent="flex-start"
        sx={{ width: '100%' }}
        alignItems="center"
      >
        <AddIcon
          style={{ marginLeft: '10px', color: '#141965', fontSize: '50px' }}
        />
        <label htmlFor={`upload-${id}-file`}>
          <input
            style={{ display: 'none' }}
            id={`upload-${id}-file`}
            name="upload-file"
            type="file"
            onChange={handleFileChange}
          />
          {/* @ts-ignore */}
          <Button
            as="span"
            sx={{
              padding: '30px',
              fontSize: '24px',
              textTransform: 'unset',
            }}
            color="deepSapphire"
          >
            {isLoading ? 'Uploading...' : addText}
          </Button>
        </label>
      </Stack>
    </UploadVideoSection>
  );
};

export default UploadAudio;
