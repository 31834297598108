import { Box, BoxProps, styled } from '@mui/material';
import {
  i18nChangeLanguage,
  IDomEditor,
  IEditorConfig,
  IToolbarConfig,
} from '@wangeditor/editor';
import { Editor, Toolbar } from '@wangeditor/editor-for-react';
import { Field, FieldInputProps, FieldMetaProps } from 'formik';
import { useEffect, useState } from 'react';

i18nChangeLanguage('en');
interface RichTextBoxProps extends BoxProps {
  isError: boolean;
}

const DEFAULT_MAX_LENGTH = 2000;

export const RichTextBox = styled(Box)<RichTextBoxProps>(({ isError }) => ({
  border: isError ? '1px solid red' : '1px solid #0C277B',
  borderRadius: 8,
  overflow: 'hidden',
}));

const RichText = (props: any) => {
  const { name, placeholder, maxLength } = props;

  const [editor, setEditor] = useState<IDomEditor | null>(null); // TS syntax

  // Timely destroy editor, important!
  useEffect(() => {
    return () => {
      if (editor == null) return;
      editor.destroy();
      setEditor(null);
    };
  }, [editor]);
  const toolbarConfig: Partial<IToolbarConfig> = {
    toolbarKeys: ['insertLink', 'undo', 'redo'],
  }; // TS syntax
  // const toolbarConfig = { }                        // JS syntax

  const editorConfig: Partial<IEditorConfig> = {
    // TS syntax
    // const editorConfig = {                       // JS syntax
    placeholder,
    hoverbarKeys: {
      text: {
        menuKeys: ['insertLink'],
      },
    },
    maxLength: maxLength || DEFAULT_MAX_LENGTH,
  };
  return (
    <Field name={name}>
      {({
        field,
        form,
        meta,
      }: {
        field: FieldInputProps<string>;
        form: any;
        meta: FieldMetaProps<string>;
      }) => {
        return (
          <RichTextBox isError={!!meta.error}>
            <Toolbar
              editor={editor}
              defaultConfig={toolbarConfig}
              mode="default"
              style={{ borderBottom: '1px solid #ccc' }}
            />
            <Editor
              defaultConfig={editorConfig}
              value={meta.value}
              onCreated={setEditor}
              onChange={(editor) => {
                form.setFieldValue(name, editor.getHtml());
              }}
              mode="default"
              style={{
                minHeight: '200px',
                maxHeight: '400px',
                overflowY: 'scroll',
              }}
            />
          </RichTextBox>
        );
      }}
    </Field>
  );
};

export default RichText;
