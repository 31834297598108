const dateUtil = {
  formatDate: (isoDate: any) => {
    const date = new Date(isoDate);
    let [month, monthNumber, day, year, time] = [
      date.toLocaleString('en-us', { month: 'short' }),
      date.toLocaleString('en-us', { month: 'numeric' }),
      date.toLocaleString('en-us', { day: 'numeric' }),
      date.getFullYear(),
      date.toLocaleTimeString('en-US'),
    ];
    if (monthNumber.length < 2) {
      monthNumber = '0' + monthNumber;
    }
    if (day.length < 2) {
      day = '0' + day;
    }
    return { month, monthNumber, day, year, time };
  },
  getDate: (numberOfDate: number): Date => {
    const date = new Date();
    date.setDate(new Date().getDate() + numberOfDate);
    return date;
  },
  commonFormattedDate: (value: any): string => {
    const { monthNumber, day, year } = dateUtil.formatDate(value);
    return [year, monthNumber, day].join('-');
  },
};

export default dateUtil;
