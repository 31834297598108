const commonUtil = {
  checkEmptyObject: (obj: any) => {
    return Object.keys(obj).length === 0;
  },
  parseTotalPage: ({
    totalItem,
    page,
    limit,
  }: {
    totalItem: number;
    page: number;
    limit: number;
  }) => {
    let tempTotalPage = page;
    if (!!totalItem && totalItem >= limit) {
      tempTotalPage = Math.ceil(totalItem / limit);
    }
    return tempTotalPage;
  },
  isHtml: (str: string) => {
    const htmlRegex = /<[a-z][\s\S]*>/i;
    return htmlRegex.test(str);
  },
};

export default commonUtil;
