import axios from '@/utils/axios.util';
import { useMutation } from '@tanstack/react-query';

export const useUploadAudioToS3 = () => {
  return useMutation((payload: { fileContents: File }) => {
    const { fileContents } = payload;
    const formData = new FormData();
    formData.append('file', fileContents);
    return axios.post('/cms-podcast/upload-audio', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
      withCredentials: false,
    });
  });
};

export const useUploadToS3 = () => {
  return useMutation(
    (payload: { fileType: string; putUrl: string; fileContents: File }) => {
      const { fileType, putUrl, fileContents } = payload;

      return axios.put(putUrl, fileContents, {
        headers: { 'Content-Type': fileType },
        withCredentials: false,
      });
    }
  );
};

const REPLACE_TYPE: { [key: string]: string } = {
  'audio/mpeg': 'audio/mp3',
  'audio/x-wav': 'audio/wav',
};

export const getSignUrl = (fileType: string) => {
  let newFileType: string = fileType;
  if (['audio/mpeg', 'audio/x-wav'].includes(newFileType)) {
    newFileType = REPLACE_TYPE?.[newFileType];
  }
  if (newFileType.split('/').length > 1) {
    const [, type] = newFileType.split('/');
    return axios.get(`/cms-daily/sign-url/${type}`);
  }
  return axios.get(`/cms-daily/sign-url/${newFileType}`);
};
