import 'react-toastify/dist/ReactToastify.css';
import './App.css';

import ProtectedRoute from '@/components/auth/ProtectedRoute';
import ToastMessage, {
  ToastType,
} from '@/components/common/ToastMessage/ToastMessage';
import commonConstants from '@/constants/common.constants';
import { fetchDefaultOptions } from '@/redux/slices/serverOptionsSlice';
import store, { persistor } from '@/redux/store';
import { themeMaterial } from '@/theme';
import loadable from '@loadable/component';
import { ThemeProvider } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';
import CreateEditFocusAudio from './pages/focus-audios/CreateEditFocusAudio';

const PageNotFound = loadable(
  () => import('./pages/page-not-found/PageNotFound')
);
const Auth = loadable(() => import('./pages/auth'));
const ForgotPassword = loadable(() => import('./pages/auth'), {
  resolveComponent: (components) => components.ForgotPassword,
});
const Login = loadable(() => import('./pages/auth'), {
  resolveComponent: (components) => components.Login,
});
const ResetPassword = loadable(() => import('./pages/auth'), {
  resolveComponent: (components) => components.ResetPassword,
});
const Journeys = loadable(() => import('./pages/journeys'));
const ReorderJourneys = loadable(() => import('./pages/journeys'), {
  resolveComponent: (components) => components.ReorderJourneys,
});
const CreateEditJourneyCategory = loadable(() => import('./pages/journeys'), {
  resolveComponent: (components) => components.CreateEditJourneyCategory,
});
const Events = loadable(() => import('./pages/events'));
const Layout = loadable(() => import('./components/layout/Layout'));
const Learn = loadable(() => import('./pages/learn'));
const Podcasts = loadable(() => import('./pages/podcasts'));
const CreateEditEvent = loadable(
  () => import('./pages/events/CreateEditEvent')
);
const CreateEditLearn = loadable(
  () => import('./pages/learn/CreateEditArticle')
);
const CreateEditDailyExercises = loadable(
  () => import('./pages/daily-exercises/CreateEditDailyExercise')
);
const CreateEditDailyMeditation = loadable(
  () => import('./pages/meditations/CreateEditMeditation')
);
const CreateEditPodcast = loadable(
  () => import('./pages/podcasts/CreateEditPodcast')
);
const PromoAnalytics = loadable(() => import('./pages/promo-analytics'));
const CreateEditPromoter = loadable(
  () => import('./pages/promo-analytics/CreateEditPromoter')
);

const onError = (error: any) => {
  let message =
    error?.response?.data?.message ??
    error?.message ??
    commonConstants.SOMETHING_WENT_WRONG;
  if (error?.meta?.errorMessage) {
    message = error?.meta?.errorMessage;
  }
  // Fallback Error Catch If we don't define onError when using useQuery
  message && toast(<ToastMessage text={message} type={ToastType.ERROR.type} />);
};

const MAX_AGE_REACT_QUERY_PERSIST = 1000 * 60 * 60 * 24; // 24 hours

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      onError,
    },
    mutations: {
      retry: false,
      onError,
    },
  },
});

const persister = createSyncStoragePersister({
  storage: window.localStorage,
});

function App() {
  store.dispatch(fetchDefaultOptions());
  return (
    <PersistQueryClientProvider
      client={queryClient}
      persistOptions={{ persister, maxAge: MAX_AGE_REACT_QUERY_PERSIST }}
    >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ReactQueryDevtools />
        <HelmetProvider>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <ThemeProvider theme={themeMaterial}>
                <BrowserRouter>
                  <Routes>
                    <Route path="auth" element={<Auth />}>
                      <Route index element={<Login />} />
                      <Route
                        path="forgot-password"
                        element={<ForgotPassword />}
                      />
                      <Route
                        path="reset-password"
                        element={<ResetPassword />}
                      />
                    </Route>
                    <Route
                      path="/"
                      element={
                        //@ts-ignore
                        <ProtectedRoute>
                          <Layout />
                        </ProtectedRoute>
                      }
                    >
                      <Route index element={<Journeys />} />
                      <Route path="journeys">
                        <Route index element={<Journeys />} />
                        <Route path="reorder" element={<ReorderJourneys />} />
                        <Route
                          path="create-daily-exercise"
                          element={<CreateEditDailyExercises />}
                        />
                        <Route
                          path="edit-daily-exercises/:exerciseId"
                          element={<CreateEditDailyExercises />}
                        />
                        <Route
                          path="create-weekly-focus"
                          element={<CreateEditFocusAudio />}
                        />
                        <Route
                          path="edit-weekly-focus/:focusAudioId"
                          element={<CreateEditFocusAudio />}
                        />
                        <Route
                          path="create-meditation-video"
                          element={<CreateEditDailyMeditation />}
                        />
                        <Route
                          path="edit-meditation-video/:meditationId"
                          element={<CreateEditDailyMeditation />}
                        />
                        <Route path="category">
                          <Route
                            path="create"
                            element={<CreateEditJourneyCategory />}
                          />
                          <Route
                            path="edit/:categoryId"
                            element={<CreateEditJourneyCategory />}
                          />
                        </Route>
                      </Route>

                      <Route path="learn">
                        <Route index element={<Learn />} />
                        <Route path="create" element={<CreateEditLearn />} />
                        <Route
                          path="edit/:learnId"
                          element={<CreateEditLearn />}
                        />
                      </Route>
                      <Route path="events">
                        <Route index element={<Events />} />
                        <Route path="create" element={<CreateEditEvent />} />
                        <Route
                          path="edit/:eventId"
                          element={<CreateEditEvent />}
                        />
                      </Route>
                      <Route path="podcasts">
                        <Route index element={<Podcasts />} />
                        <Route path="create" element={<CreateEditPodcast />} />
                        <Route
                          path="edit/:podcastId"
                          element={<CreateEditPodcast />}
                        />
                      </Route>
                      <Route path="promo-analytics">
                        <Route index element={<PromoAnalytics />} />
                        <Route path="create" element={<CreateEditPromoter />} />
                      </Route>
                    </Route>
                    <Route path="*" element={<PageNotFound />} />
                  </Routes>
                </BrowserRouter>
                <ToastContainer
                  className="toaster-container"
                  position="top-center"
                  autoClose={2000}
                  hideProgressBar={true}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                  closeButton={false}
                />
              </ThemeProvider>
            </PersistGate>
          </Provider>
        </HelmetProvider>
      </LocalizationProvider>
    </PersistQueryClientProvider>
  );
}

export default App;
