import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import dateUtil from '@/utils/date.util';
import { Button, Stack, styled } from '@mui/material';
import { useState } from 'react';
import { DateRange } from 'react-date-range';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';
import commonConstants from '@/constants/common.constants';

export const ClearButton = styled(Button)({
  height: '40px',
  textTransform: 'unset',
  border: '1px solid #141965',
  borderRadius: '100px',
  color: '#fff',
  backgroundColor: '#141965',
  '&:hover': {
    backgroundColor: '#141965',
    opacity: 0.9,
  },
});

type DayRangePickerQueryParamsModalProps = {
  onCloseModal: () => void;
};

const DayRangePickerQueryParamsModal = ({
  onCloseModal,
}: DayRangePickerQueryParamsModalProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [ranges, setRanges] = useState([
    {
      startDate: searchParams.get(commonConstants.QUERY_KEYS.DATE_FROM)
        ? moment(
            searchParams.get(commonConstants.QUERY_KEYS.DATE_FROM)
          ).toDate()
        : undefined,
      endDate: searchParams.get(commonConstants.QUERY_KEYS.DATE_TO)
        ? moment(searchParams.get(commonConstants.QUERY_KEYS.DATE_TO)).toDate()
        : undefined,
      key: 'selection',
    },
  ]);

  const onChangeDate = (item: any) => {
    const formattedStartDate = dateUtil.commonFormattedDate(
      item.selection.startDate
    );
    const formattedEndDate = dateUtil.commonFormattedDate(
      item.selection.endDate
    );
    setRanges([item.selection]);
    if (formattedStartDate !== formattedEndDate) {
      searchParams.set(
        commonConstants.QUERY_KEYS.DATE_FROM,
        formattedStartDate
      );
      searchParams.set(commonConstants.QUERY_KEYS.DATE_TO, formattedEndDate);
      searchParams.set(commonConstants.QUERY_KEYS.PAGE, '1');
      setSearchParams(searchParams);
    }
  };
  return (
    <Stack gap={2}>
      <ClearButton
        onClick={() => {
          searchParams.delete('dateFrom');
          searchParams.delete('dateTo');
          setSearchParams(searchParams);
          onCloseModal();
        }}
        fullWidth
        variant="contained"
      >
        Clear Date
      </ClearButton>
      <DateRange onChange={onChangeDate} ranges={ranges} />
    </Stack>
  );
};

export default DayRangePickerQueryParamsModal;
