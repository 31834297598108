import { styled, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Field } from 'formik';

const StyledField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: '1px solid #0C277B',
      borderRadius: '8px',
    },
  },
});

const Date = (props: any) => {
  const { name, label, ...rest } = props;

  return (
    <Field name={name}>
      {({ field, form, meta }: { field: any; form: any; meta: any }) => {
        return (
          <DatePicker
            label={label}
            inputFormat="dd/MM/yyyy"
            value={field.value}
            onChange={(newValue) => {
              form.setFieldValue(name, newValue);
            }}
            renderInput={(params) => (
              <StyledField
                {...rest}
                {...params}
                error={Boolean(meta.error)}
                helperText={meta.touched && meta.error}
              />
            )}
          />
        );
      }}
    </Field>
  );
};

export default Date;
