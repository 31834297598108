import { Stack, styled, Typography } from '@mui/material';

export const AlertBox = styled(Stack)({
  height: '100%',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  border: '1px solid black',
  padding: '1.4rem',
  gap: '1rem',
  borderRadius: '8px',
});

export const AlertText = styled(Typography)({
  fontSize: '1rem',
  fontWeight: 400,
  color: '#000',
});
