import UploadPhotoSrc from '@/assets/upload_photo.svg';
import UploadVideoSrc from '@/assets/upload_video.svg';
import httpStatusCode from '@/constants/httpStatusCode.constants';
import { useFileChange } from '@/hooks/common/useFileChange';
import { getSignUrl, useUploadToS3 } from '@/hooks/common/useUploadToS3';
import { Box, Modal, styled } from '@mui/material';
import { useEffect, useId, useState } from 'react';
import HoverVideoPlayer from 'react-hover-video-player';
import ReactPlayer from 'react-player/lazy';

import { Image, UploadButton, UploadVideoSection } from './UploadSectionStyled';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'max-content',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

type UploadSectionProps = {
  errors: any;
  setFieldValue: (name: string, value: string) => void;
  name: string;
  value: string;
  typeAllow: string[];
  isVideo?: boolean;
};

const StyledLabel = styled('label')({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
});

const UploadSection = ({
  errors,
  setFieldValue,
  name,
  value,
  typeAllow,
  isVideo = true,
}: UploadSectionProps) => {
  const { fileContents, fileType, handleFileChange } = useFileChange(
    typeAllow /* ['video', 'image'] */
  );
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const id = useId();

  const { mutateAsync, isLoading } = useUploadToS3();

  useEffect(() => {
    const handleUpload = async () => {
      try {
        if (fileType && fileContents) {
          const response = await getSignUrl(fileType);
          if (response.status === httpStatusCode.OK) {
            const { fileUrl, putUrl } = response?.data;
            const res = await mutateAsync({ fileContents, fileType, putUrl });
            if (res.status === httpStatusCode.OK) {
              setFieldValue(name, fileUrl);
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    handleUpload();
  }, [fileType, fileContents, mutateAsync, setFieldValue, name]);
  return (
    // @ts-ignore
    <UploadVideoSection isError={Boolean(errors[name])}>
      {!!value ? (
        <>
          {isVideo ? (
            <>
              <HoverVideoPlayer
                videoSrc={value}
                style={{ position: 'absolute' }}
                videoStyle={{ height: '380px' }}
              />
              <UploadButton onClick={handleOpen}>Watch</UploadButton>
              <Modal
                open={!!open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <ReactPlayer url={value} controls />
                </Box>
              </Modal>
            </>
          ) : (
            <Image src={value} alt="photo" />
          )}
          <StyledLabel htmlFor={`upload-${id}-file`}>
            <input
              style={{ display: 'none' }}
              id={`upload-${id}-file`}
              name="upload-file"
              type="file"
              onChange={handleFileChange}
            />

            {/* @ts-ignore */}
            <UploadButton variant="text" component="span">
              {isLoading
                ? 'Uploading...'
                : `Change ${isVideo ? 'Video' : 'Image'} File`}
            </UploadButton>
          </StyledLabel>
        </>
      ) : (
        <>
          <img
            src={isVideo ? UploadVideoSrc : UploadPhotoSrc}
            alt="upload section img"
          />
          <StyledLabel htmlFor={`upload-${id}-file`}>
            <input
              style={{ display: 'none' }}
              id={`upload-${id}-file`}
              name="upload-file"
              type="file"
              onChange={handleFileChange}
            />
            {/* @ts-ignore */}
            <UploadButton variant="text" component="span">
              {isLoading
                ? 'Uploading...'
                : `Upload ${isVideo ? 'Video' : 'Image'} File`}
            </UploadButton>
          </StyledLabel>
        </>
      )}
    </UploadVideoSection>
  );
};

export default UploadSection;
