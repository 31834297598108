import '@wangeditor/editor/dist/css/style.css';

import AutocompleteSearch from './AutocompleteSearch';
import Date from './Date';
import DateRange from './DateRange';
import Input from './Input';
import RichText from './RichText';
import Select from './Select';
import Textarea from './Textarea';
import TimeRange from './TimeRange';
import MultiSelect from './MultiSelect';

export const CONTROL_TYPE = {
  INPUT: 'input',
  TEXTAREA: 'textarea',
  SELECT: 'select',
  MULTI_SELECT: 'multi-select',
  DATE: 'date',
  DATE_RANGE: 'dateRange',
  TIME_RANGE: 'timeRange',
  AUTOCOMPLETE: 'autocomplete',
  RICH_TEXT: 'richText',
} as const;

type FormikControlProps = {
  control: ValueOf<typeof CONTROL_TYPE>;
  name: string;
  [key: string]: any;
};

const FormikControl = (props: FormikControlProps) => {
  const { control, ...rest } = props;
  switch (control) {
    case CONTROL_TYPE.INPUT:
      return <Input {...rest} />;
    case CONTROL_TYPE.SELECT:
      return <Select {...rest} />;
    case CONTROL_TYPE.DATE:
      return <Date {...rest} />;
    case CONTROL_TYPE.TEXTAREA:
      return <Textarea {...rest} />;
    case CONTROL_TYPE.DATE_RANGE:
      return <DateRange {...rest} />;
    case CONTROL_TYPE.TIME_RANGE:
      return <TimeRange {...rest} />;
    case CONTROL_TYPE.AUTOCOMPLETE:
      return <AutocompleteSearch {...rest} />;
    case CONTROL_TYPE.RICH_TEXT:
      return <RichText {...rest} />;
    case CONTROL_TYPE.MULTI_SELECT:
      return <MultiSelect {...rest} />;
    default:
      return null;
  }
};

export default FormikControl;
