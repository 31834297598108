import { Divider, Stack } from '@mui/material';

import { ActionButtonContained } from '../../common/Styled/CommonStyled';
import {
  Text16Weight700Desert,
  Text18Weight600Dune,
  Text18Weight700Dune,
} from '../../common/Styled/TypographyStyed';

type ViewPodcastPlaysModalProps = {
  onCloseModal: () => void;
  data: {
    audios: { title: string; playsCount: number }[];
    name: string;
  };
};

const ViewPodcastPlaysModal = ({
  onCloseModal,
  data,
}: ViewPodcastPlaysModalProps) => {
  const { audios, name } = data;
  return (
    <Stack>
      <Text18Weight700Dune pt={2} pb={2} sx={{ textAlign: 'center' }}>
        {name}
      </Text18Weight700Dune>
      <Divider />
      <Stack p={2} gap={3} mt={4} mb={4}>
        {audios.map(({ title, playsCount }, index) => (
          <Stack
            key={index}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            gap={2}
          >
            <Text18Weight600Dune
              sx={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
            >
              {title}
            </Text18Weight600Dune>
            <Text16Weight700Desert>
              {playsCount > 1 ? `${playsCount} Plays` : `${playsCount} Play`}
            </Text16Weight700Desert>
          </Stack>
        ))}
      </Stack>
      <ActionButtonContained
        sx={{ margin: '10px 20px 20px 20px' }}
        onClick={() => onCloseModal()}
      >
        OK
      </ActionButtonContained>
    </Stack>
  );
};

export default ViewPodcastPlaysModal;
