import {
  Box,
  Chip,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  styled,
} from '@mui/material';
import { Field, FieldInputProps, FieldMetaProps } from 'formik';

const StyledField = styled(Select)({
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: '#0C277B',
  },
});

const StyledBox = styled(Box)({
  border: '1px solid #0C277B',
  borderRadius: 4,
});

const StyledChip = styled(Chip)({
  backgroundColor: '#DFE1FF',
});

const MultiSelect = (props: any) => {
  const { name, label, disabled, options, ...rest } = props;

  return (
    <Field name={name}>
      {({
        field,
        form,
        meta,
      }: {
        field: FieldInputProps<string[]>;
        form: any;
        meta: FieldMetaProps<string[]>;
      }) => {
        const handleChange = (event: SelectChangeEvent<string>) => {
          const list = new Set<string>(meta.value);
          list.add(event.target.value);
          //@ts-ignore
          form.setFieldValue(name, [...list]);
        };
        const handleDelete = (id: string) => {
          const list = new Set<string>(meta.value);
          list.delete(id);
          //@ts-ignore
          form.setFieldValue(name, [...list]);
        };
        const selectedItems = options.filter(
          (item: { label: string; value: string }) => {
            const list = new Set<string>(meta.value);
            return list.has(item.value);
          }
        );
        return (
          <StyledBox
            p={2}
            sx={(theme) => ({
              borderColor:
                meta.touched && meta.error
                  ? theme.palette.error.main
                  : 'inherit',
            })}
          >
            <FormControl
              disabled={disabled}
              fullWidth
              sx={{ minWidth: 120 }}
              error={Boolean(meta.error)}
            >
              <InputLabel id={name}>{label}</InputLabel>
              <StyledField
                value={''}
                sx={{ borderRadius: '8px' }}
                labelId={name}
                name={name}
                inputProps={{
                  onBlur: form.handleBlur,
                }}
                label={label}
                variant="outlined"
                onChange={handleChange}
                {...rest}
              >
                {options.map((type: any) => (
                  <MenuItem key={type.label} value={type.value}>
                    {type.label}
                  </MenuItem>
                ))}
              </StyledField>
              <FormHelperText>{meta.touched && meta.error}</FormHelperText>
            </FormControl>
            <Stack direction="row" gap={2} mt={2} flexWrap="wrap">
              {selectedItems?.map((item: { label: string; value: string }) => (
                <StyledChip
                  key={item.label}
                  label={item.label}
                  variant="outlined"
                  onDelete={() => handleDelete(item.value)}
                />
              ))}
            </Stack>
          </StyledBox>
        );
      }}
    </Field>
  );
};

export default MultiSelect;
