import { useNavigate } from 'react-router-dom';
import useAxios from '../useAxios';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ALLOW_STATUS, JOURNEYS_TYPES } from '@/constants/common.constants';
import { toast } from 'react-toastify';
import ToastMessage, {
  ToastType,
} from '@/components/common/ToastMessage/ToastMessage';
import getQueryKey, { JOURNEYS } from '@/utils/get-query-key';

export type MutatePayloadFocusAudioType = {
  status: (typeof ALLOW_STATUS)[number];
  image: string;
  audio: string;
  title: string;
  guideName: string;
  description: string;
};

export const useCreateNewFocusAudio = () => {
  const myAxios = useAxios();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (payload: MutatePayloadFocusAudioType) => {
      return myAxios.post(
        `/cms-${JOURNEYS_TYPES.WEEKLY_FOCUS}/create`,
        payload
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries([JOURNEYS]);
    },
    onSuccess: () => {
      navigate(`/?type=${JOURNEYS_TYPES.WEEKLY_FOCUS}`, { replace: true });
      toast(
        <ToastMessage
          text="Successfully Created Focus Audio."
          type={ToastType.SUCCESS.type}
        />
      );
    },
  });
};

export const useEditFocusAudio = (focusAudioId: string) => {
  const myAxios = useAxios();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  return useMutation(
    (payload: MutatePayloadFocusAudioType) =>
      myAxios.put(
        `/cms-${JOURNEYS_TYPES.WEEKLY_FOCUS}/${focusAudioId}/update`,
        payload
      ),
    {
      onSettled: () => {
        queryClient.invalidateQueries([JOURNEYS]);
        queryClient.invalidateQueries(
          getQueryKey.focusAudioQueryKey(focusAudioId)
        );
      },
      onSuccess: () => {
        navigate(`/?type=${JOURNEYS_TYPES.WEEKLY_FOCUS}`, { replace: true });
        toast(
          <ToastMessage
            text={'Successfully Updated Focus audio.'}
            type={ToastType.SUCCESS.type}
          />
        );
      },
    }
  );
};

export const useDeleteFocusAudio = (focusAudioId: string) => {
  const myAxios = useAxios();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  return useMutation(
    () =>
      myAxios.delete(
        `/cms-${JOURNEYS_TYPES.WEEKLY_FOCUS}/${focusAudioId}/delete`
      ),
    {
      onSettled: () => {
        queryClient.invalidateQueries([JOURNEYS]);
      },
      onSuccess: () => {
        navigate(`/?type=${JOURNEYS_TYPES.WEEKLY_FOCUS}`, { replace: true });
        toast(
          <ToastMessage
            text={'Successfully Deleted Focus audio.'}
            type={ToastType.SUCCESS.type}
          />
        );
      },
    }
  );
};

export const useGetFocusAudio = (focusAudioId: string) => {
  const myAxios = useAxios();
  return useQuery(
    getQueryKey.focusAudioQueryKey(focusAudioId),
    () =>
      myAxios.get(
        `/cms-${JOURNEYS_TYPES.WEEKLY_FOCUS}/details/${focusAudioId}`
      ),
    {
      enabled: !!focusAudioId,
      select: (result) => result.data,
    }
  );
};
