import { themes } from '@/theme';
import { styled, Typography } from '@mui/material';

export const Heading18 = styled(Typography)({
  fontSize: '18px',
  fontWeight: 400,
});

// Font Size 72
export const Text72Weight800 = styled(Typography)({
  fontWeight: 800,
  fontSize: '72px',
  color: themes.light.colorBlack,
});

// Font Size 32
export const Text32Weight600DeepSapphire = styled(Typography)({
  fontWeight: 600,
  fontSize: '32px',
  color: themes.light.colorDeepSapphire,
});
export const Text32Weight600 = styled(Typography)({
  fontWeight: 600,
  fontSize: '32px',
});

// Font Size 27
export const Text27Weight600 = styled(Typography)({
  fontWeight: 600,
  fontSize: '27px',
  color: themes.light.colorBlack,
});
export const Text27DuneWeight700 = styled(Typography)({
  fontWeight: 700,
  fontSize: '27px',
  color: themes.light.colorDune,
});

// Font Size 18
export const Text18Weight700Dune = styled(Typography)({
  fontWeight: 700,
  fontSize: '18px',
  color: themes.light.colorDune,
});
export const Text18Weight600Dune = styled(Typography)({
  fontWeight: 600,
  fontSize: '18px',
  color: themes.light.colorDune,
});
export const Text18Weight500 = styled(Typography)({
  fontWeight: 500,
  fontSize: '18px',
  color: themes.light.colorBlack,
});
export const Text18Weight500Desert = styled(Typography)({
  fontWeight: 500,
  fontSize: '18px',
  color: themes.light.colorDesert,
});

// Font Size 16
export const Text16Weight700Desert = styled(Typography)({
  fontWeight: 700,
  fontSize: '16px',
  color: themes.light.colorDesert,
});
export const Text16 = styled(Typography)({
  fontWeight: 400,
  fontSize: '16px',
  color: themes.light.colorBlack,
});
export const Text16Gray = styled(Typography)({
  fontWeight: 400,
  fontSize: '16px',
  color: themes.light.colorGray,
});
export const Text16CometWeight400 = styled(Typography)({
  fontWeight: 400,
  fontSize: '16px',
  color: themes.light.colorComet,
});
export const Text16Weight500 = styled(Typography)({
  fontWeight: 500,
  fontSize: '16px',
  color: themes.light.colorBlack,
});
export const Text16Weight500Gray = styled(Typography)({
  fontWeight: 500,
  fontSize: '16px',
  color: themes.light.colorGray,
});
export const Text16Weight600 = styled(Typography)({
  fontWeight: 600,
  fontSize: '16px',
  color: themes.light.colorBlack,
});

// Font Size 14
export const Text14Weight500 = styled(Typography)({
  fontWeight: 500,
  fontSize: '14px',
  color: themes.light.colorBlack,
});
export const Text14 = styled(Typography)({
  fontWeight: 400,
  fontSize: '14px',
  color: themes.light.colorBlack,
});
export const Text14Weight500Gray = styled(Typography)({
  fontWeight: 500,
  fontSize: '14px',
  color: themes.light.colorGray,
});
export const Text14Weight400Gray = styled(Typography)({
  fontWeight: 400,
  fontSize: '14px',
  color: themes.light.colorGray,
});

// Font Size 12
export const Text12Weight500Gray = styled(Typography)({
  fontWeight: 500,
  fontSize: '12px',
  color: themes.light.colorGray,
});
export const Text12Weight600 = styled(Typography)({
  fontWeight: 600,
  fontSize: '12px',
  color: themes.light.colorBlack,
});

export const CommonErrorText = styled(Text16)({
  color: themes.light.colorElectricRed,
});
