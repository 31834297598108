export const JOURNEYS_TYPES = {
  DAILY_EXERCISES: 'daily',
  WEEKLY_FOCUS: 'weekly-focus',
  // FIVE_DAYS_VIDEOS: 'fiveDays',
  MEDITATIONS: 'meditations',
} as const;

const commonConstants = {
  SOMETHING_WENT_WRONG: 'Something went wrong.',
  DAILY_EXERCISE_DEFAULT_QUERY_PARAMS: {
    page: 1,
    numberItemPerPage: 25,
  },
  INTRO_EXERCISE_DEFAULT_QUERY_PARAMS: {
    page: 1,
    numberItemPerPage: 25,
  },
  LEARN_DEFAULT_QUERY_PARAMS: {
    page: 1,
    numberItemPerPage: 25,
  },
  EVENTS_DEFAULT_QUERY_PARAMS: {
    page: 1,
    numberItemPerPage: 25,
  },
  PODCASTS_DEFAULT_QUERY_PARAMS: {
    page: 1,
    numberItemPerPage: 25,
  },
  PROMO_ANALYTICS_DEFAULT_QUERY_PARAMS: {
    page: 1,
    numberItemPerPage: 25,
  },
  STATUS: {
    PUBLISHED: 'published',
    DRAFT: 'draft',
    DELETE: 'delete',
    PAST: 'past',
  } as const,
  STATUS_1: {
    DRAFT: 'DRAFT',
    PUBLISHED: 'PUBLISHED',
  },
  TIMER: {
    // milliseconds
    TIME_20_SEC: 1000 * 20,
    TIME_1_MINS: 1000 * 60,
  },
  STATUS_COLOR: {
    published: '#0FBE00',
    draft: '#E70000',
    past: '#1E90FF',
  } as const,
  DATE_FORMAT: {
    DATE1: 'DD/MM/yyyy',
    DATE2: 'YYYY-MM-DD',
  } as const,
  JOURNEY_TYPE_LIST: [
    {
      value: JOURNEYS_TYPES.DAILY_EXERCISES,
      label: 'Daily Exercises',
      addVideo: {
        url: '/journeys/create-daily-exercise',
        text: 'Add Daily Exercise',
      },
      reorderText: 'Reorder Daily Exercise',
      filterCategoryText: 'Filter Course',
    },
    {
      value: JOURNEYS_TYPES.WEEKLY_FOCUS,
      label: 'Weekly Focus Audios',
      addVideo: {
        url: '/journeys/create-weekly-focus',
        text: 'Add Audio',
      },
      reorderText: 'Reorder Audio',
      filterCategoryText: '',
    },
    // {
    //   value: JOURNEYS_TYPES.FIVE_DAYS_VIDEOS,
    //   label: '5 Days Intuitive',
    //   addVideo: {
    //     url: '/journeys/create-five-days-video',
    //     text: 'Add Video',
    //   },
    //   reorderText: 'Reorder Video',
    // },
    {
      value: JOURNEYS_TYPES.MEDITATIONS,
      label: 'Meditations',
      addVideo: {
        url: '/journeys/create-meditation-video',
        text: 'Add Meditation',
      },
      reorderText: 'Reorder Meditation',
      filterCategoryText: 'Filter Category',
    },
  ] as const,
  QUERY_KEYS: {
    TYPE: 'type',
    CATEGORIES: 'categories',
    CATEGORY: 'category',
    DATE_FROM: 'dateFrom',
    DATE_TO: 'dateTo',
    PAGE: 'page',
    Q: 'q',
    TOKEN: 'token',
  },
  RELEVANT_ANSWER: [
    {
      value: '',
      label: 'None',
    },
    {
      value: 'I want to tap into and release my psychic abilities!',
      label: 'I want to tap into and release my psychic abilities!',
    },
    {
      value:
        'I want to ignite and foster the beginning of a spiritual awakening!',
      label:
        'I want to ignite and foster the beginning of a spiritual awakening!',
    },
    {
      value:
        'I want to level up my decision-making game and become a more confident and decisive person!',
      label:
        'I want to level up my decision-making game and become a more confident and decisive person!',
    },
    {
      value: 'I want insight into people and more meaningful relationships!',
      label: 'I want insight into people and more meaningful relationships!',
    },
    {
      value:
        'I want to recognise more pathways for the opportunity to flow into my life!',
      label:
        'I want to recognise more pathways for the opportunity to flow into my life!',
    },
    {
      value: 'I am new and overflowing with curiosity, where do I start?',
      label: 'I am new and overflowing with curiosity, where do I start?',
    },
  ],
};

export const ALLOW_STATUS = [
  commonConstants.STATUS.PUBLISHED,
  commonConstants.STATUS.DRAFT,
] as const;

export default commonConstants;
