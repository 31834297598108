import { Button, Stack, StackProps, styled } from '@mui/material';

type UploadVideoSectionProps = {
  isError?: boolean;
  height?: string;
} & StackProps;

export const UploadVideoSection = styled(Stack)<UploadVideoSectionProps>(
  ({ isError, height }) => ({
    width: '100%',
    height: height ? height : '380px',
    border: `1px dashed ${isError ? 'red' : '#141965'}`,
    justifyContent: 'center',
    alignItems: 'center',
    gap: '2rem',
    position: 'relative',
  })
);

export const StyledButton = styled(Button)({
  // minWidth: '150px',
  height: '40px',
  textTransform: 'unset',
  border: '1px solid #141965',
  borderRadius: '100px',
});

export const PublishButton = styled(StyledButton)({
  color: '#fff',
  backgroundColor: '#141965',
  '&:hover': {
    backgroundColor: '#141965',
    opacity: 0.9,
  },
});

export const UploadButton = styled(PublishButton)({
  width: 'min(360px, 100%)',
  height: '50px',
  marginInline: '10px',
});

export const Image = styled('img')({
  position: 'absolute',
  maxHeight: '380px',
  objectFit: 'cover',
  maxWidth: '100%',
});
